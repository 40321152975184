body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar{
	max-width: 240px;
  height: 100vh;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-subitem-text {
  font-size: 0.8rem;
}

.footer{
  width: 100%;
  height: auto;
  position: absolute;
  position: 0;
  background-color: rgba(6, 45, 23, 1) !important;
  padding-top: 5vh;
  /*padding-left: 12vw;*/
  /*padding-right: 12vw;*/
}

.footer-left{
  text-align: justify;
  padding-left: 12vw;
 
}

.footer-right{
  text-align: justify;
  padding-right: 8vw;
 
}


.footer-address{
    margin-top: 5vh;
    font-size: 0.8em;
}

.footer-address > p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.vertical-line{
  border-left: 2px solid gray;
  height: 20vh;
  margin-top: 2vh;
  margin-right: 5vw;
  margin-left: 5vw;
}

.link1:hover {
  cursor: pointer;
  opacity: 0.5;
  text-decoration: none !important; 
}

.active{
  font-weight: bold;
}

.text-color-default{
  color: inherit !important;
}

.text-color-green{
  color: #116B3A !important;
}

.text-color-white{
  color: white !important;
}

.header-lang{
  background-color: rgba(241, 241, 241, 1);
  text-align: right;
  padding-right: 15vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  font-size: 0.6rem !important;
}

.header-menu{
  padding-right: 13vw;
  padding-left: 9vw;
  padding-bottom: 1.8vh;
  padding-top: 1.8vh;
  /*padding: 1.5vh;*/
  font-size: 0.8rem !important ;
}

.menu-dropdown{
  font-size: 0.8rem !important;
}

.header-menu-item{
  opacity: 0.5;
  text-align: center;
  vertical-align: center;
  color: inherit !important;
}

.header-menu-item:hover{
  opacity: 1;
  cursor: pointer;
  text-decoration: none !important;
}



.section{
  padding-top: 2vh;
  padding-bottom: 2vh
}

.gridlist-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;

}

.grey-section{
  background-color: rgba(242, 242, 242, 1) !important;
}

.proyek-section{
  padding-left: 12vw;
  padding-right: 12vw;

}

.penerima-section{
  text-align: center;
}

.gridlist{
  width: 70vw;
 
}

.container-gallery{
  position: relative;
}

.paper-gallery{
  border-radius: 20px !important;
} 

.gallery-multi {
  margin-top: 0px;
  margin-bottom: 0.3vh;
  align-items: right;
  opacity: 0.5;
}

.div-gallery-multi:hover{
  opacity: 0.7;
  cursor: pointer;
}

.image-gallery{
  border-radius: 20px !important;
  width: 100%;
  height: 300px;
  object-fit: cover;

}

.image-gallery-overlay{
  position: absolute;
  top: 200px;
  height: 100px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba( 11, 69, 38,0), rgba( 11, 69, 38,1));
  /*background-color: green;*/

  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  color: white;
  font-weight: bold;
}

.image-gallery-overlay > p {
    position: absolute;
    bottom: 0px;
    width: 80%;
    padding-left: 10%;
    transition: bottom 0.5s;
    font-size: 1.2rem;
}

.container-gallery:hover{
  cursor: pointer;
}

.container-gallery:hover > .image-gallery-overlay{
    opacity: 0.8;
    background-image: linear-gradient(to bottom, rgba(  17, 107, 58,0), rgba(  17, 107, 58,1));
}
.container-gallery:hover > .image-gallery-overlay > p{

    bottom: 20px;

}



.image-gallery-overlay:after{
  background: -moz-linear-gradient(top, green 10%, white 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
}

.beranda-tentang{
  text-align: justify;
  padding-left: 12vw;
  padding-right: 2vw;
}

.beranda-video{
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 12vw;
}

.beranda-logo{
    height: 20vh;
}
.beranda-logo1{
  width: 17vw;
}

.beranda-logo2{
    height: 10vh;
}

.footer-logo{
  height: 5vh
}


.overlay{
  position: absolute; 
  bottom: 0; 
  top: 0;
  left: 0;
  right: 0;
  /* Black see-through */
  color: #f1f1f1; 

  opacity:0.5;
  color: white;
  font-size: 20px;
  padding: 20px;

  border-radius: 30px;

}

.filter{
  position: absolute; 
  bottom: 0; 
  top: 0;
  left: 0;
  right: 0;
  /* Black see-through */
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;

  border-radius: 30px;

}

.filter-2{
  position: absolute; 
  bottom: 0; 
  top: 0;
  left: 0;
  right: 0;
  /* Black see-through */
  opacity:1;
  color: white;
  font-size: 20px;
  padding: 20px;

  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;

}

.blue{
  background-color: rgba(0, 76, 164, 0.462745098039216);
}

.gray{
  background-color: rgba(164, 104, 0, 0.325490196078431);
}

.green{
  background-color: rgba(0, 91, 59, 0.501960784313725);
}

.white{
  background-color: rgba(255, 255, 255, 0.588235294117647);
}

.overlay-img{
  width: 100%;
  height: 100%;
  padding: 0px;
  border-radius: 30px;
  object-fit: cover;
}

.overlay-content{
  position: absolute; 
  color: white; 
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.overlay-content{
  position: absolute; 
  color: white; 
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.overlay-content1{
  position: absolute; 
  color: white; 
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.overlay-content-2{
  position: absolute; 
  color: #116B3A; 
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.overlay-content > img{
  width: 50px;
  margin-bottom: -20px;
}

.overlay-content1 > img{
  width: 50px;
  margin-bottom: -20px;
}

.overlay-img-2{
  max-width: 40px;
  margin-right: 10px;
  
}

.overlay-text{
  vertical-align: top;
}


.overlay-content > .overlay-img-2, .overlay-content > h1{
    display: inline-flex;
    margin-top: 0vh;
}

.overlay-content1 > .overlay-img-2, .overlay-content1 > h1{
    display: inline-flex;
    margin-top: 0vh;
}

.overlay-content-2 > .overlay-img-2, .overlay-content-2 > h1{
    display: inline-flex;
    margin-top: 0vh;
}

.proyek-container{
  position: relative;
  border:none;
  padding: 0px;
  height: 40vh;
  margin-bottom: 10px;

}

.total-section{
  position: relative;
  height: 50vh;

}

.penerima-section{
  position: absolute;
  top: 50%;
  margin-left: -2vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-bottom: 5vh;
}

.penerima-font{
  font-size: 3rem;
}

.image-center{
  float: center;
}

.logo-section{
  
  padding-top: 5vh;
  padding-bottom: 5vh;
  text-align: center;
}


.image-fokus{
  border-radius: 20px !important;
  width: 100%;

  object-fit: cover;

}

.image-fokus-overlay{
  position: absolute;
  bottom: 0vh;
  left: 5vw;
  width: 100%;
  /*background-color: green;*/
  color: white;
  font-weight: bold;
}

.fokus_header{
    opacity: 0.5;
    text-align: center;
    padding-bottom: 3vh;
}

.fokus_header:hover{
  opacity: 1;
  cursor: pointer;
}

.fokus_header_active{
    opacity: 1;
    color: #38825A;
    font-weight: bold;
    border-bottom: 1px solid #38825A;
}

.tahun-button{
    text-align: center;
    padding: 1.2vh;
    border-radius: 5px;
}

.tahun-change:hover{
    cursor: pointer;
}


.fokus_component1{
    background-color: rgba(255, 221, 0, 0.5);
}

.fokus_component2{
    background-color: rgba(0, 187, 112, 0.5);
}

.fokus_component3{
    background-color: rgba(162, 59, 255, 0.5);
}

.fokus_component4{
    background-color: rgba(255, 80, 80, 0.5);
}

.input-custom{
    padding: 1.2vh;
    border-radius: 5px;
    width: 90%;
}

.textarea-custom{
    padding: 1.2vh;
    border-radius: 5px;
    width: 90%;
}

.fokus-progres{
  width: 8% !important;
  position: relative !important;
  bottom: 0 !important;
  z-index: 2000 !important;
}

.breadcrumb-container{
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 12vw;

}

.container-content{
  width: inherit;
  margin-left: 10vw;
  padding-right: 13vw;
}

.video-gallery{
  height: 75vh;
  width: 100%;
  border-radius: 20px !important;
}





@media only screen and (max-width: 768px) {

    .header-lang{
      background-color: white;
      text-align: left;

      font-size: 1rem !important;
      color: black;
    }

    .header-menu{
      padding: 1.5vh;
     
    }

    .header-menu-item{
      opacity: 1;
      text-align: left;
      vertical-align: center;
      color: inherit !important;
    }

    .header-menu-item:hover{
      opacity: 1;
      text-decoration: none !important;
      background-color: lightgray !important;
    }

    .beranda-tentang{
      text-align: justify;
      padding-left: 10vw;
      padding-right: 10vw;
    }

    .beranda-video{
      padding-top: 2vw;
      padding-left: 10vw;
      padding-right: 10vw;
      height: 40vh;
    }

    .total-section{
      position: relative;
      height: 70vh;


    }

    .penerima-section{
        position: relative;
        top: 0%;

        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        margin-bottom: 0vh;
      }

    .beranda-logo{
        height: 15vh;
    }

    .beranda-logo2{
        height: 5vh;
    }
    .footer-logo{
      height: 2vh
    }


    .image-fokus-overlay{
      
      top: -1.5vh;
      left: 2vw;
      width: 100%;
      /*background-color: green;*/
      color: white;
      font-weight: bold;
    }

}

@media only screen and (max-width: 600px) {

  .image-gallery{
      height: 30vh;
    }
    .image-fokus-overlay{
      
      top: -2vh;
      left: 2vw;
      min-width: 1200px;
      /*background-color: green;*/
      color: white;
      font-weight: bold;
    }

    .image-gallery-overlay{
      
      top: 20vh;
      height: 10vh;
   
    }

    .breadcrumb-container{
        margin-top: 1vh;
        margin-bottom: 1vh;
        margin-left: 8vw;
    }

    .total-section{
      height: 95vh;
    }
    .beranda-logo{
      height: 15vh;
      margin-top: 3vh;
    }
    .beranda-logo1{
      width: 40vw;
      margin-bottom: -2vh;
    }
    .logo-section{
      text-align: center;
    }
    .proyek-section{
    padding-left: 5vw;
    padding-right: 5vw;

  }
  .container-content{
      text-align: justify;
      margin-left: 5vw;
      padding-right: 5vw;
    }

  .video-gallery{
    height: 20vh;
  }

}

@media only screen and (min-width: 1444px) {
  .header-lang{

    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 0.6rem !important;
  }
  .vertical-line{
    height: 15vh;
  }
  .footer-logo{
    height: 4vh 
  }
  .footer-address{
    margin-top: 2vh;
    font-size: large;
  }
  .beranda-logo{
    height: 10vh;
  }
  .beranda-logo1{
    width: 8vw;
    margin-left: 4vw;
  }
  .total-section{
    height: 20vh;
  }
  .penerima-section{
    margin-left: 2vw;
  }
  .beranda-logo2{
    height: 7vh;
  }
  .proyek-container{
    height: 25vh;
  }
  .image-gallery{
    height: 400px;
  }

  .image-gallery-overlay{
    top: 240px;
    height: 160px;
  }

  .image-gallery-overlay > p {
      bottom: 0px;
      padding-left: 10%;
  }

  .container-gallery:hover > .image-gallery-overlay > p{
      bottom: 20px;
  }
  .image-fokus-overlay{
      
    top: -1vh;
    left: 4vw;
    min-width: 1200px;
    font-size: 1.5em;
    /*background-color: green;*/
    color: white;
    font-weight: bold;
  }

  .breadcrumb-container{
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

  .video-gallery{
    height: 30vh;
  }
 

}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.lng-select{
    background-color: white;
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 40px;
    font-size: 14px;
    vertical-align: middle;
}

.div-center{
  text-align: center;
}

.div-right{
  text-align: right;
}

.dialog-custom > div > div{
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.modal-content{
  min-width: 300px;
}

#modal-submit{
  background-color: rgba(6, 181, 111, 1);
  color: #FFFFFF;
  border-radius: 10px;  
  margin-top: 5%;
  padding: 5px 20px 5px 20px;
  font: 0.5rem;
}

.close-modal{
  cursor: pointer;
}


.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 17vh;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 100;
  cursor: pointer;
}

.image-upload-wrap {

  width: 100%;
  height: 100%;
  position: relative;

}

.drag-text {
  margin-top: 10vh;
  text-align: center;
}


@media only screen and (min-width: 1444px) {
  #modal-submit{

    margin-top: 5%;
    padding: 5px 20px 5px 20px;
    font-size: 1.5rem;
  }

}